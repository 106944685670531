.info-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .tooltip-container {
    padding-bottom: 1rem;
    border-bottom: .1rem solid white;
  }

  .user-description-container {
    border-bottom: white solid 0.1rem;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
    gap: .5rem;
  }

  .column-info-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .column-info {
      display: flex;
      flex-direction: column;
      gap: .5rem;

      .column-description {
        padding-left: .5rem;
      }
    }
  }
}
