.counter-panel {
  height: 30rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  .title {

  }

  .subtitle {

  }

  .value-container {
    height: 100%;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    .value {
      font-size: 10rem;
    }
  }
}
