@use '@carbon/layout';

.login-screen {
  background-color: transparent;
  position: relative;
  flex-grow: 1;

  #login-panel {
    //margin-top: layout.$spacing-09;
    //width: 100px;
    //height: 100px;
    position: absolute;
    top: 34%;
    left: 45%;
    margin: -50px 0 0 -50px;
    //position: relative;
    height: fit-content;
  }

  #login-header {
    margin-bottom: layout.$spacing-07;
    color: black;
  }

  #login-header p {
    color: black;
  }

  .login-component {
    width: auto;
  }

  .error {
    color: red;
  }

  .background-image-container {
    position: absolute;
    z-index: 0;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: contain;

    background-image: url("login-background-image-big.png");
    background-image: -webkit-image-set(
                    url("login-background-image-small.png") 1x,
                    url("login-background-image-big.png") 2x);
    background-image: image-set(
                    url("login-background-image-small.png") 1x,
                    url("login-background-image-big.png") 2x);
                    
    @media screen and (max-width: 1120px) {
      background-size: auto !important;
    }
    @media screen and (max-width: 1023px) {
      background-image: unset !important;
    }
  }

  .pointer {
    color: #1f5f0e !important;
  }

  .login-panel {
    display: flex;
    justify-content: start;
    margin-left: 1rem;
    margin-right: 1rem;
    position: center;

    .login-container {
      padding: 1rem;
      color: black !important;
      align-items: flex-start;
      margin-left: auto;
      margin-right: auto;
      display: grid;
      background: #ffffffc8;
      border-radius: 3px;

      .login-form-input {
        margin-bottom: layout.$spacing-03;
      }

      .login-form-elm {
        margin-bottom: layout.$spacing-03;
        width: 100%;
        max-width: 100%;
        padding-right: .8rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }

      .alternative-logins {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-items: center;
        padding-top: 1rem;
        padding-bottom: 1rem;

        .alternative-login {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border: 1px solid black;
          padding: .8rem;
          width: 100%;
          cursor: pointer;
        }
      }
    }
  }

  .login-container label {
    color: black;
  }

}
