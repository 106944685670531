.table-panel {
  max-height: 40rem;

  .cds--data-table-content {
    max-height: 20rem;
    overflow: auto;
  }

  .download-csv {
    padding-left: 1rem;
  }
}
