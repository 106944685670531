footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2.5rem;
    text-align: center;
    color: #ffffff !important;
}

p {
    font-size: 0.875rem !important;
}

a {
    color: white !important;
}

hr {
    width: 65%
}

span {
    text-align: left !important;
}

.footer-element-contact {
    width: 70%;
    text-align: left;
    margin: auto;
}

.img-footer-logo {
    width: 220px;
    height: 28px;
    margin-top: 25px;
    margin-bottom: 25px;
}

.footer-block-1 {
    display: grid;
    padding-top: 30px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 10px;
    background-color: #ffffff;
    background-image: none;
    min-height: 30px;
    border-style: solid;
    border-width: 0px;
    border-top-width: 1px;
    border-top-color: var(--ast-global-color-3);
}

.inner-footer {
    box-sizing: inherit;
}

.footer-block-2 {
    align-items: flex-start;
    margin-left: auto;
    margin-right: auto;
    grid-template-columns: repeat(7, 1fr);
    display: grid;
}

.footer-block-3 {
    margin-top: 10px;
    align-items: flex-start;
    margin-left: auto;
    margin-right: auto;
    grid-template-columns: repeat(2, 1fr);
    display: grid;

    p {
        color: black;
        text-align: left;
        padding-right: 250px;
        font-size: 16px !important;
        font-weight: 400 !important;
        line-height: 24px !important;
    }
}

.footer-big-block {
    display: grid;
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: #1F5F0E;
    background-image: none;
    width: 100%;
}

.footer-big-inner-block {
    align-items: flex-start;
    margin-left: auto;
    margin-right: auto;
    grid-template-columns: repeat(3, 1fr);
    display: grid;
    max-width: 1200px;
}

.footer-element-general {
    width: 100%;
}

.footer-copyright {
    text-align: left;
}

.footer-powered {
    text-align: right;
}

.footer-inned-25box {
    width: 25%;
}