@use '@carbon/react';
@use "@carbon/styles";
@use '@carbon/themes';
@import "https://fonts.googleapis.com/css?family=IBM+Plex+Sans+Condensed|IBM+Plex+Sans:400,600&display=swap";
@import "ColmenaTheme";


html {
  @include themes.theme($theme--colmena);
  min-height: 100%;
  display: flex;
}

body {
  display: flex;
  flex-grow: 1;
}

.cds--cc--chart-wrapper .fill-mono-2-1{ fill: #ffffff !important;}
.cds--cc--chart-wrapper .fill-mono-2-2{ fill: #e9efe7 !important;}
.cds--cc--chart-wrapper .fill-mono-2-3{ fill: #d2dfcf !important;}
.cds--cc--chart-wrapper .fill-mono-2-4{ fill: #bccfb7 !important;}
.cds--cc--chart-wrapper .fill-mono-2-5{ fill: #a5bf9f !important;}
.cds--cc--chart-wrapper .fill-mono-2-6 { fill: #8faf87 !important;}
.cds--cc--chart-wrapper .fill-mono-2-7{ fill: #799f6e !important;}
.cds--cc--chart-wrapper .fill-mono-2-8{ fill: #628f56 !important;}
.cds--cc--chart-wrapper .fill-mono-2-9{ fill: #4c7f3e !important;}
.cds--cc--chart-wrapper .fill-mono-2-10{ fill: #356f26 !important;}
.cds--cc--chart-wrapper .fill-mono-2-11{ fill: #1f5f0e !important;}

.cds-charts-mono-2-1{color: #ffffff !important;}
.cds-charts-mono-2-2{color: #e9efe7 !important;}
.cds-charts-mono-2-3{color: #d2dfcf !important;}
.cds-charts-mono-2-4{color: #bccfb7 !important;}
.cds-charts-mono-2-5{color: #a5bf9f !important;}
.cds-charts-mono-2-6{color: #8faf87 !important;}
.cds-charts-mono-2-7{color: #799f6e !important;}
.cds-charts-mono-2-8{color: #628f56 !important;}
.cds-charts-mono-2-9{color: #4c7f3e !important;}
.cds-charts-mono-2-10{color: #356f26 !important;}
.cds-charts-mono-2-11{color: #1f5f0e !important;}

:root {

  .pointer {
    cursor: pointer;
  }

  .text-center {
    text-align: center;
  }


  .main-container {
    display: flex;
    flex-grow: 1;
    margin-bottom: 10rem;
    position: relative;
    min-height: 100vh;
  }

  .inner-main-container {
    display: flex;
    flex-grow: 1;
    height: calc(100% - 3rem);
    padding: 0 !important;
    padding-bottom: 2.5rem;
    margin-bottom: 50px;
  }

  .cds--header {
    background: #1F5F0E !important;
  }

  .cds--content {
    background: themes.$background;
  }

  .page-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 1rem;
  }

  .edit-action {
    cursor: pointer;
  }
  
  .cds--chart-holder {
    --cds-charts-mono-2-1: #ffffff !important;
    --cds-charts-mono-2-2: #e9efe7 !important;
    --cds-charts-mono-2-3: #d2dfcf !important;
    --cds-charts-mono-2-4: #bccfb7 !important;
    --cds-charts-mono-2-5: #a5bf9f !important;
    --cds-charts-mono-2-6: #8faf87 !important;
    --cds-charts-mono-2-7: #799f6e !important;
    --cds-charts-mono-2-8: #628f56 !important;
    --cds-charts-mono-2-9: #4c7f3e !important;
    --cds-charts-mono-2-10: #356f26 !important;
    --cds-charts-mono-2-11: #1f5f0e !important;
  }

  .cds--cc--chart-wrapper .stop-color-mono-2-1{ stop-color: var(--cds-charts-mono-2-1, #ffffff) !important;}
  .cds--cc--chart-wrapper .stop-color-mono-2-2{ stop-color: var(--cds-charts-mono-2-2, #e9efe7) !important;}
  .cds--cc--chart-wrapper .stop-color-mono-2-3{ stop-color: var(--cds-charts-mono-2-3, #d2dfcf) !important;}
  .cds--cc--chart-wrapper .stop-color-mono-2-4{ stop-color: var(--cds-charts-mono-2-4, #bccfb7) !important;}
  .cds--cc--chart-wrapper .stop-color-mono-2-5{ stop-color: var(--cds-charts-mono-2-5, #a5bf9f) !important;}
  .cds--cc--chart-wrapper .stop-color-mono-2-6{ stop-color: var(--cds-charts-mono-2-6, #8faf87) !important;}
  .cds--cc--chart-wrapper .stop-color-mono-2-7{ stop-color: var(--cds-charts-mono-2-7, #799f6e) !important;}
  .cds--cc--chart-wrapper .stop-color-mono-2-8{ stop-color: var(--cds-charts-mono-2-8, #628f56) !important;}
  .cds--cc--chart-wrapper .stop-color-mono-2-9{ stop-color: var(--cds-charts-mono-2-9, #4c7f3e) !important;}
  .cds--cc--chart-wrapper .stop-color-mono-2-10{ stop-color: var(--cds-charts-mono-2-10, #356f26) !important;}
  .cds--cc--chart-wrapper .stop-color-mono-2-11{ stop-color: var(--cds-charts-mono-2-11, #1f5f0e) !important;}

  @import "styles-index";
}