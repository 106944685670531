.profile-container {
  width: 100%;
  max-width: 500px;
  margin: 120px auto 120px;
  background-color: #fff;
  padding: 0 20px 20px;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.075);
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.075);
  text-align: center;

  h2, h5 {
    color: var(--cds-text-inverse);
  }

  .status-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    flex-direction: column;
    padding: 1rem 0;
  }

  .avatar-flip {
    background-color: #fff;
    border-radius: 100px;
    overflow: hidden;
    height: 150px;
    width: 150px;
    position: relative;
    margin: auto;
    top: -60px;
    box-shadow: 0 0 0 13px #f0f0f0;
    -webkit-box-shadow: 0 0 0 13px #f0f0f0;
    -moz-box-shadow: 0 0 0 13px #f0f0f0;
  }

  .avatar-flip img {
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 100px;
  }

  .avatar-flip img:first-child {
    z-index: 1;
  }

  .user-info-container {
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .user-info-item {
      display: inline-grid;
      grid-template-columns: 25% 60% 5%;
      gap: 1rem;
      align-items: center;
      justify-items: start;

      span {
        color: var(--cds-text-inverse);
      }
    }
  }

  .profile-actions-container {
    width: 100%;
    display: flex;
    justify-content: center;

    .edit-profile {
      gap: 1rem;
    }
  }
}

