.panel-placeholder {
  //height: 30rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  //border: solid 1px white;
}
