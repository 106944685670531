.panel-placeholder {
  height: 30rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  //border: solid 1px white;
  .reload-holder {
    display: flex;
    flex-direction: row;
    gap: 1rem;

    .reload-loading .hidden {
      display: none !important;
    }

    .reload-loading-placeholder {
      width: 1.5rem;
      height: 2rem;
    }

    .reload-button {
      color: #ffffff !important;
    }
  }
}
