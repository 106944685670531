@use "@carbon/styles";
@import "@carbon/charts/styles/styles.scss";

.axis-title {
  color: #000000 !important;
}

.chart-holder {

  @include styles.theme(styles.$g10);
  @media screen and (max-width: 830px) {
    height: 50rem !important;
  }
  color: #000000 !important;
  //background: var(--cds-background) !important;
  background: #E8E8E8 !important;

  .bx--cc--chart-wrapper {
    background: #E8E8E8 !important;;
  }

  p {
    color: #000000 !important;
  }

  .chart-grid-backdrop .stroked {
    stroke: #E8E8E8 !important;
    fill: #E8E8E8!important;

    .tick {
      line {
        stroke: #ffffff !important;
      }

      text {
        fill: #000000 !important;
        color: #000000 !important;
      }
    }
  }

  .chart-grid-backdrop {
    fill: #E8E8E8 !important;
  }
  
  .axis {
    .tick {
      text {
        fill: #000000 !important;
      }
    }
  }

  .axis-title {
    color: #000000 !important;
  }

  .bx--cc--tooltip {
    background: #000000 !important;
  }
}

