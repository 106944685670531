.dashboard {
  width: 100%;
  flex-grow: 1;
  display: flex;
  max-width: 100vw;

  .dashboard-inner {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .cds--label {
    color: #000000 !important;
  }

  .cds--form__helper-text {
    color: #000000 !important;
  }
  .cds--select-input {
    color: #000000 !important;
  }

  .cds--select-option {
    color: #E8E8E8 !important;
  }

  .chart-wrapper {
    //min-height: 30rem;

    .no-panels-wrapper {
      padding-top: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1rem;

      .no-panels-wrapper-inner {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 1rem;
      }
    }
  }

  .error-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    .no-content-wrapper {
      padding-top: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1rem;

      .no-content-wrapper-inner {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 1rem;
      }
    }
  }

  .status-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    flex-direction: column;
    padding: 1rem 0;
  }

  .charts-container {
    row-gap: 1rem;

    .chart-container {
      margin-top: 1rem;
      margin-bottom: 1rem;
      padding: 1rem;
    }
  }

  .selection-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 5rem;
    padding-right: var(--cds-grid-margin);
    padding-left: var(--cds-grid-margin);

    .dashboard-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;

      .reload-icon {
        cursor: pointer;
      }

      .rotate-all {
        animation: rotate-infinite .8s ease infinite;
      }

      @keyframes rotate-infinite {
        from {
          transform: rotate(0deg);
        }
        to {

          transform: rotate(360deg);
        }
      }
    }

    .selection-wrapper-inner-margins {
      --cds-grid-gutter-start: calc(var(--cds-grid-gutter) / 2);
      --cds-grid-gutter-end: calc(var(--cds-grid-gutter) / 2);
      --cds-grid-column-hang: calc(var(--cds-grid-gutter) / 2);
      margin-right: var(--cds-grid-gutter-end);
      margin-left: var(--cds-grid-gutter-start);
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: space-between;
      flex-grow: 1;
      gap: 1rem;

      .selector-inner {
        width: 100%;
        max-width: 30rem;
        color: #000000;

        .dashboard-selector {
          max-width: 30rem;
        }

        .no-dashboards-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 1rem;

          .no-dashboards-wrapper-inner {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            gap: 1rem;
          }
        }
      }
    }

  }
}


